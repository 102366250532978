
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import {active} from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {
    AddTagsModal
    },
  methods: {
    onChange() 
    { 
      this.formData.category_select = '' 
      }
  },

  setup() {

    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addReportModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const loadingHsn = ref<boolean>(false);
    const router = useRouter(); 
    const shape = ref([]);
    var tag_id_list : any [] = []
    var tag_text_list : any [] = []

    onMounted( async () => {
      //const db_data = {}
      await setTagData(taglist.value)
       await setParentCatData()
      await setChildCatData()
    });

     const parentcat = ref([]);
     const setParentCatData = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
          parentcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const childcat = ref([]);
     const setChildCatData = async () => {
      
        loading.value = true;
        try {
          var values = {
            "category_id": formData.value.parent_select
            }
          await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
          childcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }


    let rawImg = ref() ;
    function encodeImagetoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawImg.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawImg.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       
       };
      
      }

      let document_details = ref();
      function UploadDoc(element) {
        const doc = element.target.files[0];
        const reader1 = new FileReader();
        reader1.readAsDataURL(doc);
        reader1.onload = (event) => {
        document_details.value = {fname:element.target.files[0].name, fdata: reader1.result}
       }
     }
    
    const taglist = ref([]);
    const setTagData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term": data  }
          await store.dispatch(Actions.CUST_TAG_LIST, values).then(({ data }) => {
          taglist.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const addtag : any[] = [];
    
    const formData = ref({
      name: "",      
      description: "",
      thumbnail_image_name:"",
      thumbnail_image_data:"",  
      doc_name: "",
      doc_data:"",
      tag_select: "",  
      parent_select:"",
      category_select: "",
      active: "",
    });    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Category Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
      hsn_select: [
        {
          required: true,
          message: "Hsn is required",
          trigger: "change",  
        }
      ],
      shape_select: [
        {
          required: true,
          message: "Shape is required",
          trigger: "change",  
        }
      ],
      form_select: [
        {
          required: true,
          message: "Category Form is required",
          trigger: "change",  
        }
      ],
      stage_select: [
        {
          required: true,
          message: "Stage is required",
          trigger: "change",  
        }
      ],
      length_select: [
        {
          required: true,
          message: "Length is required",
          trigger: "change",  
        }
      ],
      industry_select: [
        {
          required: true,
          message: "Industry type is required",
          trigger: "click",  
        }
      ],
       tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",  
        }
      ],
    });

    const setReportsData = async (data) => {
     
    for (let j = 0; j < data.tag_select.length; j++){
          if (data.tag_select[j]){
            tag_text_list.push(data.tag_select[j])
          }
      }
      for (let i=0; i<tag_text_list.length; i++)
      {
        const db_data = {
          "tag_name": tag_text_list[i],
          "created_user_id" : 1
          }
           await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
           
           addtag.push(data.tag_id);
           
          })
      }
    
      const merge = tag_text_list;
      
      const db_data = {
        "report_id" : 0,
        "report_title": data.name,
        "report_description": data.description,
        "thumbnail_image_name": rawImg.value.file_name,
        "thumbnail_image_data": rawImg.value.file_data,
        "doc_name": document_details.value.fname,
        "doc_data": document_details.value.fdata,
        "parent_category_id": data.parent_select,
        "category_id": data.category_select,
        "report_tags_json": merge,
        "active": data.active,
        }
        
        await store.dispatch(Actions.CUST_REPORT_SAVE, db_data).then(({ data }) => {
          if (data){
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Reports has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addReportModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

   
    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setReportsData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });    

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;

      //       Swal.fire({
      //         text: "Company has been successfully created.",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn btn-primary",
      //         },
      //       }).then(() => {
      //         hideModal(addCompanyModalRef.value);              
      //         router.go(0)
      //       });
      //     }, 2000);
      //   } else {
      //     Swal.fire({
      //       text: "Sorry, looks like there are some errors detected, please try again.",
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Ok, got it!",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //     });
      //     return false;
      //   }
      // });
    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addReportModalRef,
      encodeImagetoBase64L,
      setParentCatData,
      setChildCatData,
      UploadDoc,
      setTagData,
      document_details,
      childcat,
      parentcat,
      length,
      rawImg,
      addtag,
      shape,
      taglist,
      parent,
      active_list_data,
    };
  },
});
