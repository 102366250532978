

import { defineComponent, ref, onMounted, computed, onUpdated, HtmlHTMLAttributes } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddReportsModal from "@/components/modals/forms/AddReportsModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import { Modal } from "bootstrap";
import { active } from "@/core/data/genericData";
import { DayTableModel } from "@fullcalendar/common";

export default defineComponent({
  name: "reports-listing",
  components: {
    ExportCustomerModal,
    AddReportsModal, 
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    interface Report {
      report_id: number,      
      user: {
        avatar: string;
        thumbnail_image_name: string;
      },      
      active: {
        label: string;
        color: string;
      },      
      report_title: string,
      report_description: string,
      report_display_time: string,
      doc_short_url: string,
      category_name: string,
    }

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

   
    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getReportList("")
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getReportList("")
    }
    
    function copyText() {
            document.querySelector("#copy_id")
            alert("Text copied")
        }

    var tableData = ref<Array<Report>>([]);
  
       const getReportList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = {
          "sort":"desc", 
          // "is_approved": 1, 
          // "active": '',  
          "page"  : parseInt(paginationData.value['activePage']), 
          "records_per_page" : parseInt(paginationData.value['perPage']) 
          }

        await store.dispatch(Actions.CUST_REPORT_LIST, values).then(({ data }) => {
          
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<Report>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {


            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              report_id : data.result_list[j]['report_id'],
              user : {
                thumbnail_image_name : data.result_list[j]['thumbnail_image_name'],
                avatar : data.result_list[j]['thumbnail_image_name'],              
              },
              active: {
                label: active_label,
                color: active_color
              },
              report_title: data.result_list[j]['report_title'],
              report_description: data.result_list[j]['report_description'],
              report_display_time: data.result_list[j]['report_display_time'],
              doc_short_url: data.result_list[j]['doc_short_url'],
              category_name: data.result_list[j]['category_name'],
            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });


      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<Report>>([]);                      

      onMounted( async () => {
        await getReportList("")
        setCurrentPageBreadcrumbs("Reports", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].report_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getReportList("");
          console.log(search.value);
          
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      copyText,
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getReportList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      //image_modal,
      //standard_modal,
      //attr_range_modal,
      //attr_modal
    };  

  }  

});




